import React from "react"
import { Div, Text, Row, Col, Container, Image, Anchor } from "atomize"
// import Loadable from "@loadable/component"

import Map from "../../images/de.inline.svg"
import map from "../../images/de.svg"

function SupportedStates() {
  return (
    <Div tag="section" id="Unterstützte Bundesländer">
      <Container>
        <Div
          p={{ t: "4rem", b: "6rem" }}
          border={{ b: "1px solid" }}
          borderColor="gray300"
        >
          <Row>
            <Col size={{ xs: 12, md: 6, lg: 6 }} offset={{ xs: 0, md: 1 }}>
              <Div p={{ l: { lg: "1rem" } }}>
                <Text
                  textSize={{ xs: "title", sm: "heading", md: "display1" }}
                  textWeight="500"
                  fontFamily="secondary"
                  textAlign="left"
                  m={{ b: "1rem" }}
                >
                  Unterstützte Bundesländer
                </Text>
                <Text
                  textSize="subheader"
                  textColor="medium"
                  maxW="35rem"
                  m={{ b: "3rem" }}
                >
                  Falls Du Daten über Gesundheitsämter oder Teststationen der
                  derzeit nur teilweise unterstützten Bundesländer hast und uns
                  diese zur Verfügung stellen kannst, kontaktiere uns bitte,
                  damit wir diese mit aufnehmen und den Funktionsumfang des
                  Assistenten erweitern können. Solltest Du Daten über die{" "}
                  <strong>Testzentren oder Schwerpunktpraxen</strong> der nur
                  teilweise unterstützten Bundesländer haben,{" "}
                  <Anchor href="mailto:corona@fusionbase.com">
                    kontaktiere uns bitte
                  </Anchor>
                  , damit wir diese mit aufnehmen können.
                </Text>
                <Row>
                  <Col size={{ xs: 12, md: 12 }} p={{ y: "1rem" }}>
                    <Div
                      m={{ r: "1rem" }}
                      w="100%"
                      d="flex"
                      flexDir="row"
                      align="left"
                    >
                      <Div
                        bg="success800"
                        rounded="circle"
                        h="2rem"
                        w="2rem"
                        m={{ y: "auto" }}
                      />
                      <Div
                        d="flex"
                        flexDir="column"
                        maxW={{ xs: "70%", sm: "80%", md: "100%" }}
                      >
                        <Text
                          textSize="subheader"
                          textAlign="left"
                          textWeight="500"
                          textColor="medium"
                          m={{ l: "1rem" }}
                        >
                          Komplette Unterstützung
                        </Text>
                        <Text
                          textSize="paragraph"
                          textAlign="left"
                          // textWeight="500"
                          // textColor="medium"
                          m={{ l: "1rem" }}
                        >
                          (Fallzahlen, Hotspot, Beschränkungen,
                          Gesundheitsämter, Teststationen)
                        </Text>
                      </Div>
                    </Div>
                  </Col>
                  <Col size={{ xs: 12, md: 12 }} p={{ y: "1rem" }}>
                    <Div
                      m={{ r: "1rem" }}
                      w="100%"
                      d="flex"
                      flexDir="row"
                      align="left"
                    >
                      <Div
                        bg="warning800"
                        rounded="circle"
                        h="2rem"
                        w="2rem"
                        m={{ y: "auto" }}
                      />
                      <Div
                        d="flex"
                        flexDir="column"
                        maxW={{ xs: "70%", sm: "80%", md: "100%" }}
                      >
                        <Text
                          textSize="subheader"
                          textAlign="left"
                          textWeight="500"
                          textColor="medium"
                          m={{ l: "1rem" }}
                        >
                          Teilweise Unterstützung
                        </Text>
                        <Text
                          textSize="paragraph"
                          textAlign="left"
                          // textWeight="500"
                          // textColor="medium"
                          m={{ l: "1rem" }}
                        >
                          (Fallzahlen, Hotspot, Beschränkungen,
                          Gesundheitsämter)
                        </Text>
                      </Div>
                    </Div>
                  </Col>
                </Row>
              </Div>
            </Col>
            <Col
              size={{ xs: 12, md: 5, lg: 4 }}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              {/* NOTE: display raw svg if on desktop for tooltips else as scalable image if on mobile */}
              <Div maxW="30rem" d={{ xs: "none", md: "block" }}>
                <Map />
              </Div>
              <Div
                maxH="18rem"
                d={{ xs: "block", md: "none" }}
                m={{ t: "2rem" }}
                textAlign="center"
              >
                <Image src={map} h="100%" maxH="18rem" w="auto" />
              </Div>
            </Col>
          </Row>
        </Div>
      </Container>
    </Div>
  )
}

export default SupportedStates
